import React from "react"
import { navigate } from "gatsby"

export const onRouteUpdate = ({ location }) => {
  if (location.pathname === "/patirtis/") {
    navigate("/straipsniai/", { replace: true })
  }
}

export const onRenderBody = ({ setHeadComponents }) => {
  setHeadComponents([
    <script
      key="cookiebot"
      id="Cookiebot"
      src="https://consent.cookiebot.com/uc.js"
      data-cbid="d4011acc-edf9-4b95-be87-253d5e040ded"
      data-blockingmode="auto"
      type="text/javascript"
    />,
  ])
}
