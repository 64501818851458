exports.components = {
  "component---src-pages-404-js": () => import("./../../../src/pages/404.js" /* webpackChunkName: "component---src-pages-404-js" */),
  "component---src-pages-index-js": () => import("./../../../src/pages/index.js" /* webpackChunkName: "component---src-pages-index-js" */),
  "component---src-pages-issiusta-js": () => import("./../../../src/pages/issiusta.js" /* webpackChunkName: "component---src-pages-issiusta-js" */),
  "component---src-pages-zinute-issiusta-js": () => import("./../../../src/pages/zinute-issiusta.js" /* webpackChunkName: "component---src-pages-zinute-issiusta-js" */),
  "component---src-templates-all-posts-template-js": () => import("./../../../src/templates/all-posts-template.js" /* webpackChunkName: "component---src-templates-all-posts-template-js" */),
  "component---src-templates-category-posts-template-js": () => import("./../../../src/templates/category-posts-template.js" /* webpackChunkName: "component---src-templates-category-posts-template-js" */),
  "component---src-templates-page-template-js": () => import("./../../../src/templates/page-template.js" /* webpackChunkName: "component---src-templates-page-template-js" */),
  "component---src-templates-post-template-js": () => import("./../../../src/templates/post-template.js" /* webpackChunkName: "component---src-templates-post-template-js" */),
  "component---src-templates-redirect-template-js": () => import("./../../../src/templates/redirect-template.js" /* webpackChunkName: "component---src-templates-redirect-template-js" */),
  "component---src-templates-tag-template-js": () => import("./../../../src/templates/tag-template.js" /* webpackChunkName: "component---src-templates-tag-template-js" */)
}

